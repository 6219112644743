export const bookingText = {
  online: {
    bookingLink: "https://depbeautybar.setmore.com/bookappointment",
    bookingOnlineText: "BOOK ONLINE NOW",
    bookingSummary:
      "Online booking is available through our partnership with Setmore. This is the best way to schedule and manage your appointments.",
    benefitsText: "Benefits of online booking",
    bookingBenefits: [
      "- Quick changes to appointment after booking",
      "- See estimated pricing and duration for services",
      "- Add custom message to staff with booking",
      "- Automated text reminder 1 day before appointment",
    ],
  },
  phone: {
    number: 813 - 634 - 6500,
    numberText: "CALL (503) 644-2050",
    numberSummary:
      "Do you need custom booking? Or you want to talk to someone to schedule a booking? Please call us during normal business hours.",
    numberStepText: "Quick Phone Booking Steps (optional)",
    numberSteps: [
      "Pick out your service through our online booking page.",
      "Select your preferred staff.",
      "Select your preferred time through the calendar",
      "Call us to schedule your appointment with your selected staff and time",
    ],
  },
};
