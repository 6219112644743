import {
  Box,
  Button,
  Container,
  Grid,
  Link,
  Typography,
} from "@material-ui/core";
import React from "react";
import Iframe from "react-iframe";
import PageTitle from "../all/PageTitle";
import text from "../../textInsert/text.json";

const Contact: React.FC = () => {
  return (
    <Box bgcolor='whitesmoke' pt={10} pb={10}>
      <Container>
        <Box textAlign='center'>
          <PageTitle title='Contact' />
          <Box my={3}>
            <Typography variant='h5'>Appointments & Walk-In-Welcome</Typography>
          </Box>
          <Box my={5}>
            <Grid container>
              <Grid item xs={12} sm={6}>
                <Box my={5}>
                  <Box>
                    <Typography variant='h5'>HOURS</Typography>
                  </Box>
                  <Box my={2}>
                    <Typography variant='h4'>{text.openDay}</Typography>
                    <Typography variant='h4'>{text.openHours}</Typography>
                    <Typography variant='body1'>
                      Holidays {text.holidays}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Box my={5}>
                  <Typography variant='h5'>PHONE</Typography>
                  <Box my={1}>
                    <Typography variant='h4'>{text.phone}</Typography>
                  </Box>

                  <Box m={3}>
                    <Button
                      variant='contained'
                      href={`${text.callNow}`}
                      size='large'
                    >
                      Call Now
                    </Button>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box my={5}>
                  <Typography variant='h5'>ADDRESS</Typography>
                  <Box my={2}>
                    <Link
                      href='https://goo.gl/maps/df5aVgqC6U26N9HA7'
                      rel='noreferrer'
                      // onClick={preventDefault}
                      target='_blank'
                    >
                      <Box>
                        <Typography variant='h5'>{text.address}</Typography>
                        <Typography variant='h5'>
                          {text.city},{text.state} {text.zipcode}
                        </Typography>
                      </Box>
                    </Link>
                  </Box>
                  <Box m={3}>
                    <Link
                      href={`${text.direction}`}
                      rel='noreferrer'
                      // onClick={preventDefault}
                      target='_blank'
                      style={{ textDecoration: "none", color: "black" }}
                    >
                      <Button variant='contained' size='large'>
                        GET DIRECTION
                      </Button>
                    </Link>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box
                  my={5}
                  textAlign='center'
                  display='flex'
                  justifyContent='center'
                  alignItems='center'
                  flexDirection='column'
                  mx='auto'
                >
                  <Box my={3}>
                    <Typography variant='h5'>GOOGLE MAP</Typography>
                  </Box>

                  <Box border={1} width='100%'>
                    <Iframe
                      url={`${text.mapEmbed}`}
                      width='100%'
                      height='450'
                      frameBorder={1}
                    />
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Box textAlign='center' mb={5}>
          <Button variant='contained' size='large'>
            <a
              href='tel:1-813-634-6500'
              style={{ textDecoration: "none", color: "black" }}
            >
              <Typography variant='h5'>CALL {text.phone}</Typography>
            </a>
          </Button>
        </Box>
      </Container>
    </Box>
  );
};

export default Contact;
